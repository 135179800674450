import React, { useEffect, useState } from "react";
import "../../../styles/common/Navbar.css";
import planimg1 from "../../../assets/img/price_image_01.png";
import planimg2 from "../../../assets/img/price_image_02.png";
import planimg3 from "../../../assets/img/price_image_03.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import { Config } from "../../../config";
import axios from "axios";
import Loader from "./Loader";

const PlanSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  });
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState({});

  useEffect(() => {
    if (Object?.keys(prices)?.length == 0) {
      setLoading(true);
      axios
        .get(`${Config.apiUrl}/sessionPrice`, {
          headers: {
            //'Authorization': 'Bearer ',
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          setPrices(response?.data?.data[0]);
        })
        .catch((error) => {
          console.error("There was a problem fetching the data:", error);
          setLoading(false);
        });
    }
  }, []);

  return (
    <section>
      {loading && <Loader />}
      <div className="plans-section">
        <div className="plansec-text" data-aos="fade-up">
          Start Today
        </div>
        <div>
          <div>
            <div className="plans-content">
              <div className="">
                <section>
                  <div className="plans-container">
                    <div className="plans-col" data-aos="fade-right">
                      <div className="element-wrap-plan">
                        <div style={{ marginBottom: "15px" }}>
                          <div className="element-img">
                            <img
                              loading="lazy"
                              className="upwards-hover"
                              alt="Individual Therapy"
                              src={planimg1}
                            ></img>
                          </div>
                          <div className="plan-type">
                            <div>Individual Therapy UK</div>
                          </div>
                        </div>
                      </div>
                      <div className="plan-type2">
                        <div>£{prices?.indivisual || "199"}</div>
                      </div>
                      <div>
                        <div className="plan-textbelow">
                          <div className="plan-textbelow1">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10% 0%",
                              }}
                            >
                              4 Sessions Per Month
                            </div>
                          </div>
                          <div className="plan-textbelow1">
                            <div className="plantext-div">
                              Individual therapy involves a confidential and supportive relationship between a therapist and a client. It typically focuses on addressing personal issues, emotional challenges, and mental health concerns through conversation, exploration, and tailored interventions.
                            </div>
                          </div>
                          <div className="plan-textbelow1"></div>
                          <div className="plan-button">
                            <div className="book-button">
                              <NavLink
                                to={`${Config.appUrl}get-counselling#booknowform`}
                              >
                                <span>Book Now</span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="plans-col" data-aos="fade-up">
                      <div className="element-wrap-plan">
                        <div style={{ marginBottom: "15px" }}>
                          <div className="element-img">
                            <img
                              className="upwards-hover"
                              alt="Couples Therapy"
                              src={planimg2}
                            ></img>
                          </div>
                          <div className="plan-type">
                            <div>Couples Therapy UK</div>
                          </div>
                        </div>
                      </div>
                      <div className="plan-type2" style={{ fontSize: "25px" }}>
                        <div>£{prices?.couples || "280"}</div>
                      </div>
                      <div>
                        <div className="plan-textbelow">
                          <div className="plan-textbelow1">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10% 0%",
                              }}
                            >
                              4 Sessions Per Month
                            </div>
                          </div>
                          <div className="plan-textbelow1">
                            <div className="plantext-div">
                              Couples therapy involves a collaborative process
                              between a qualified therapist and a couple to
                              address relationship issues and improve
                              communication, understanding, and intimacy.
                            </div>
                          </div>
                          <div className="plan-textbelow1"></div>
                          <div className="plan-button">
                            <div className="book-button">
                              <NavLink
                                to={`${Config.appUrl}get-counselling#booknowform`}
                              >
                                <span>Book Now</span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="plans-col" data-aos="fade-left">
                      <div className="element-wrap-plan">
                        <div style={{ marginBottom: "15px" }}>
                          <div className="element-img">
                            <img
                              loading="lazy"
                              className="upwards-hover"
                              alt="Extra Session"
                              src={planimg3}
                            ></img>
                          </div>
                          <div className="plan-type">
                            <div>Extra Sessions</div>
                          </div>
                        </div>
                      </div>
                      <div className="plan-type2" style={{ fontSize: "25px" }}>
                        <div>£{prices?.extra || "50"} </div>
                      </div>
                      <div>
                        <div className="plan-textbelow">
                          <div className="plan-textbelow1">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10% 0%",
                              }}
                            >
                              {/* € 276 Total / €46 Per Session  */}
                              Per Session
                              {/* -- */}
                            </div>
                          </div>
                          <div className="plan-textbelow1">
                            <div className="plantext-div">
                              If you feel you would like extra sessions in the month, you can add onto these by emailing 
                              <a href="mailto:info@jeantherapyonline.co.uk"> 
                              {" "}info@jeantherapyonline.co.uk{" "}
                              </a>{" "}
                               to arrange additional therapy sessions.
                            </div>
                          </div>
                          <div className="plan-textbelow1"></div>
                          <div className="plan-button">
                            <div className="book-button">
                              <NavLink
                                to={`${Config.appUrl}get-counselling#booknowform`}
                              >
                                <span>Book Now</span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="payasyougo" data-aos="fade-in">
          <div className="cta-2">
            <div className="div1">
              <img
                loading="lazy"
                alt="Jeantherapyonline Logo"
                src={planimg1}
              ></img>
            </div>
            <div className="div2">
              <h2>Pay As You Go</h2>
              <h3>£{prices?.paygo || "65"} for Session</h3>
              <div>
                Experience the flexibility of pay-as-you-go therapy with Jean
                Therapy Online. Access professional support when you need it,
                without long-term commitments or membership fees. Connect with
                experienced therapists for single sessions or schedule as
                needed, empowering you to prioritise your mental health on your
                terms.
              </div>
            </div>
            <div className="div3">
              <div className="cta-2button">
                <NavLink to={`${Config.appUrl}get-counselling#booknowform`}>
                  <span>Pay As You Go</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default PlanSection;

import React, { useState, useEffect } from "react";
import { Config, schemaMarkup } from "../../config";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import TransformCTA from "../components/Common/TransformCTA";
import { Helmet } from "react-helmet";

const FAQ = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      delay: 300,
    });
  });

  const pageName = "faq";


  const data = [
    {
      title: "How do I know if therapy is right for me?",
      desc: "Therapy can be beneficial for anyone facing challenges or seeking support in managing their emotions, relationships, or life transitions. If you're feeling overwhelmed, stressed, anxious, depressed, or simply seeking guidance in navigating life's complexities, therapy could be a helpful resource for you.",
    },
    {
      title: "What can I expect during a therapy session?",
      desc: "The sessions typically involve open and confidential discussions between you and the counsellor. Your counsellor will listen attentively, provide empathy, and support, and offer guidance in exploring your thoughts, feelings, and behaviours. Together, you'll work to identify goals and develop strategies to address your concerns.",
    },
    {
      title:
        "How long does the therapy session last, and how frequently should I attend sessions?",
      desc: "The duration of the session is 50 minutes. Some people may find relief after just a few sessions, while others may benefit from longer-term support. The frequency of sessions is usually determined collaboratively between you and your counsellor, with sessions typically weekly. ",
    },
    {
      title: "Is therapy confidential?",
      desc: "Yes, therapy is typically confidential. Counsellors are bound by professional ethics and legal standards to protect your privacy and keep your discussions confidential. However, there are some exceptions to confidentiality, such as instances where there is a risk of harm to yourself or others, or when required by law.",
    },
    {
      title: "How do I know if I've found the right counsellor for me?",
      desc: "Finding the right counsellor is essential for a positive therapy experience. It's important to consider factors such as the counsellor’s approach, communication style, personality, and expertise in addressing your specific concerns. Trust your instincts and take note of how comfortable and understood you feel during your initial sessions.",
    },
    {
      title: "What if I'm not comfortable talking about certain topics during therapy?",
      desc: "It's entirely normal to feel hesitant or uncomfortable discussing certain topics during therapy. Your counsellor will respect your boundaries and pace the sessions according to your comfort level. You're encouraged to communicate openly with your counsellor about any concerns or reservations you may have, and they can help you navigate challenging topics at a pace that feels manageable for you.",
    },
    {
      title: "How can I make the most out of my therapy experience?",
      desc: "To maximize the benefits of therapy, it's essential to approach it with openness, honesty, and commitment. Be willing to engage actively in the process, set realistic goals, and communicate openly with your counsellor about your progress, challenges, and preferences. Additionally, practice any coping strategies or homework assignments suggested by your counsellor outside of sessions to reinforce your progress and self-awareness.",
    },
    {
      title: "What’s the difference between counselling and therapy?",
      desc: "The difference between therapy and counselling often lies in their focus and depth, although the terms are sometimes used interchangeably. Counselling typically refers to a short-term process aimed at addressing specific issues or challenges, such as managing stress, improving communication, or dealing with grief. A counsellor helps clients explore their thoughts and feelings, providing guidance and support to navigate these particular concerns. Therapy, on the other hand, is often a longer- term process that delves deeper into patterns of behaviour, emotional issues, and mental health conditions.A therapist may work with clients to explore underlying causes of distress, help them understand and change deeper emotional patterns, and address more complex psychological challenges. In summary, while both a counsellor and a therapist aim to support clients, therapy often focuses on more profound, long - term healing, whereas counselling tends to address immediate and specific issues"
    },
    {
      title: "How does counselling help? ",
      desc: "Counselling helps by providing a safe, supportive space for individuals to explore their thoughts and emotions. A counsellor offers guidance and understanding, helping clients address challenges and develop coping strategies. Through tailored counselling, individuals receive support specific to their needs, while private counselling ensures a confidential environment. With personalised counselling, the approach is adapted to the client’s unique circumstances, promoting self-awareness, emotional relief, and personal growth."
    },
    {
      title: "What to expect from marriage/couples/relationship counselling?",
      desc: "In marriage counselling, couples counselling, or relationship counselling, you can expect a safe and neutral space to explore challenges in your relationship. A counsellor or therapist facilitates open and honest communication, helping both partners understand each other's perspectives. Whether through marriage therapy, relationship therapy, or couples therapy, the focus is on identifying patterns of conflict, improving communication skills, and rebuilding trust. These sessions are often tailored to your relationship's unique needs, offering strategies to strengthen your bond and navigate issues like intimacy, conflict resolution, or life transitions."
    },
    {
      title: "How to find the right therapist?",
      desc: "Finding the right therapist is essential for effective therapy. Start by identifying your needs—whether you’re seeking personalised therapy for specific challenges or tailored therapy to address broader issues. Look for a therapist with qualifications and experience relevant to your concerns, such as anxiety, relationships, or trauma. It’s also important to consider their approach, such as cognitive-behavioural therapy or holistic methods, and ensure their style aligns with your preferences. Trust and comfort are key, so don’t hesitate to schedule initial consultations to find a therapist who feels like the right fit for you."
    },
    {
      title: "How are Therapist verified?",
      desc: "Therapists are verified through a combination of education, licensing, and professional accreditation. To practice therapy, a therapist typically needs to complete a relevant degree, such as psychology, counselling, or social work, followed by supervised training hours. They must then pass exams/tests to obtain a license/registration/accreditation, which ensures they meet the required standards of competence and ethics. Verification also includes ongoing professional development and adherence to the code of conduct set by regulatory bodies. When seeking therapy, always check the therapist's credentials and licensing to ensure they are qualified and accredited. On Jean therapy online we require proof of all the above before they can register with us as a therapist."
    }
  ];

  return (
    <div className="overflow">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href='https://www.jeantherapyonline.co.uk/faq' />
        <title> Frequently Asked Questions | Jean Therapy Online </title>
        <meta name="description" content="See our FAQs or reach out via info@jeantherapyonline.co.uk if you have any further questions." />
      </Helmet>
      <div className="page-container">
        <Navbar pageName={pageName} />

        <div className="faq-container">
          <div className="faq-title" data-aos="fade-in">
            <h1>FAQs</h1>
          </div>
        </div>
      </div>

      <div className="faq-content">
        <div data-aos="fade-up">
          <div className="mt-4 pb-5">
            <div
              className="accordion accordion-flush accordian-transparent"
              id={`accordionFlushExample`}
            >
              {data.map((item, i) => {
                return (
                  <div key={i} className="accordion-item bg-transparent">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapseThree${i}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapseThree${i}`}
                      >
                        <div className="index rounded-circle bg-golden text-black me-3">
                          {i + 1}
                        </div>
                        <div> {item.title}</div>
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseThree${i}`}
                      className={`accordion-collapse collapse ${i == 0 && "show"
                        }`}
                      data-bs-parent={`#accordionFlushExample`}
                    >
                      <div className="accordion-body">
                        <p className="paragraph">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="mt-5 text-end">
            <button
              className="button mb-5"
              onClick={() => setAccordianAll(!accordianAll)}
            >
              Show {accordianAll ? 'Less' : 'More'}
              <i className={`bi bi-arrow-${accordianAll ? 'up' : 'down'} ms-2`}></i>
            </button>
          </div> */}
        </div>
      </div>

      <div className="cta-div">
        <TransformCTA />
      </div>

      <Footer />
    </div>
  );
};
export default FAQ;
